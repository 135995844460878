import { TGetStartedSection } from './GetStartedSection';

import { richTextWithPlaceholder } from 'utils/prismic';

export const useGetStartedSectionData = ({ primary }: TGetStartedSection) => {
  const { home_get_started_title, home_get_started_link_label, home_get_started_link } = primary;

  return {
    title: richTextWithPlaceholder(home_get_started_title, ''),
    linkLabel: home_get_started_link_label ?? 'Get Started',
    link: home_get_started_link?.url ?? '/',
  };
};
