import React from 'react';
import { graphql, PageProps } from 'gatsby';

import { withHomeProvider } from './HomeContext';
import HeroSection from './HeroSection';
import CircularExpansionSection, {
  CIRCULAR_EXPANSION_SLICE_TYPE,
  TCircularExpansionSection,
} from './CircularExpansionSection';
import BusinessSolutionsSection, {
  BUSINESS_SOLUTIONS_SLICE_TYPE,
  TBusinessSolutionsSection,
} from './BusinessSolutionsSection';
import MusicLibrarySection, {
  MUSIC_LIBRARY_SLICE_TYPE,
  TMusicLibrarySection,
} from './MusicLibrarySection';
import GetStartedSection, { GET_STARTED_SLICE_TYPE, TGetStartedSection } from './GetStartedSection';
import { useHomeData } from './hooks';

import Layout from 'components/Layout';

const Home: React.FC<Omit<PageProps, 'data'>> = () => {
  const { layoutProps, content } = useHomeData();

  return (
    <Layout {...layoutProps}>
      <HeroSection />
      {content.map((contentData, index) => {
        const key = `${contentData.slice_type}${index}`;
        switch (contentData.slice_type) {
          case CIRCULAR_EXPANSION_SLICE_TYPE: {
            return (
              <CircularExpansionSection key={key} {...(contentData as TCircularExpansionSection)} />
            );
          }
          case BUSINESS_SOLUTIONS_SLICE_TYPE: {
            return (
              <BusinessSolutionsSection key={key} {...(contentData as TBusinessSolutionsSection)} />
            );
          }
          case MUSIC_LIBRARY_SLICE_TYPE: {
            return <MusicLibrarySection key={key} {...(contentData as TMusicLibrarySection)} />;
          }
          case GET_STARTED_SLICE_TYPE: {
            return <GetStartedSection key={key} {...(contentData as TGetStartedSection)} />;
          }
          default: {
            return null;
          }
        }
      })}
    </Layout>
  );
};

export const homeFragment = graphql`
  fragment HomeData on PrismicHome {
    ...HomeHeroData

    data {
      content {
        ... on PrismicHomeDataContentHomecircularexpansion {
          ...HomeContentCircularExpansionData
        }
        ... on PrismicHomeDataContentHomebusinesssolutions {
          ...HomeContentBusinessSolutionsData
        }
        ... on PrismicHomeDataContentHomemusiclibrary {
          ...HomeContentMusicLibraryData
        }
        ... on PrismicHomeDataContentHomegetstarted {
          ...HomeContentGetStartedData
        }
      }
    }
  }

  fragment HomeLanguageData on PrismicHome {
    lang
    alternate_languages {
      document {
        ... on PrismicHome {
          lang
          url
        }
      }
    }
  }
`;

export default withHomeProvider(Home);
