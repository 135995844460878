import { useCallback, useMemo } from 'react';

import { TBusinessSolutionsSection } from './BusinessSolutionsSection';
import { ISolutionsTabs } from './SolutionsTabs';

import { IOption } from 'components/MobileSelect';
import { richTextWithPlaceholder } from 'utils/prismic';

export const useSolutionsSelectProps = ({
  solutions,
  activeIndex,
  handleChange,
}: ISolutionsTabs) => {
  const options = useMemo(
    () =>
      solutions.map(({ title, icon }, index) => ({
        index,
        label: title,
        icon: icon.url,
      })),
    [solutions]
  );

  const onChange = useCallback(({ index }: IOption) => {
    handleChange(index);
  }, []);

  const value = useMemo(() => options[activeIndex], [activeIndex]);

  return { options, onChange, value };
};

export const useBusinessSolutionsData = ({ primary, items }: TBusinessSolutionsSection) => {
  const { home_business_solutions_title, home_business_solutions_subtitle } = primary;

  const solutions = items.reduce(
    (
      acm,
      {
        home_business_solutions_background_image: background,
        home_business_solutions_panel_icon: icon,
        home_business_solutions_panel_title: title,
        home_business_solutions_panel_description: description,
        home_business_solutions_panel_link: link,
        home_business_solutions_panel_link_label: linkLabel,
        home_business_solutions_is_dark: isDark,
      }
    ) => {
      if (!background?.gatsbyImageData || !icon?.url) return acm;
      return [
        ...acm,
        {
          background: {
            image: background.gatsbyImageData,
            alt: background?.alt ?? icon?.alt ?? '',
          },
          icon: { url: icon.url, alt: icon?.alt ?? background?.alt ?? '' },
          title,
          description: richTextWithPlaceholder(description, ''),
          linkLabel,
          link: link?.url ?? '/',
          isDark,
        },
      ];
    },
    []
  );

  return {
    title: richTextWithPlaceholder(home_business_solutions_title, ''),
    subtitle: richTextWithPlaceholder(home_business_solutions_subtitle, ''),
    solutions,
  };
};
