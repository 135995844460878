import React from 'react';
import styled from '@emotion/styled';
import { css, keyframes } from '@emotion/react';

import { filterProps } from 'utils/helpers';

interface IRunningLine {
  texts: string[];
  reverse?: boolean;
}

const animationDurationSeconds = 120;

const runningLineAnimation = keyframes`
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(-100%, 0);
  }
`;

const Container = styled.div`
  ${({ theme: { mq } }) => css`
    display: flex;
    position: relative;
    height: 18.375rem;
    overflow: hidden;

    ${mq.md} {
      height: 5.625rem;
    }
  `}
`;

const LineInstance = styled.p`
  position: absolute;
  margin: 0 auto;
  overflow: hidden;
  white-space: nowrap;
`;

const TextsContainer = styled('span', filterProps('quickStart', 'reverse'))<{
  quickStart?: boolean;
  reverse?: boolean;
}>`
  ${({ quickStart = false, reverse = false }) => css`
    display: inline-block;
    padding-left: 100%;
    animation: ${runningLineAnimation} ${animationDurationSeconds}s linear infinite;
    animation-delay: ${quickStart ? animationDurationSeconds / -2 : 0}s;
    animation-direction: ${reverse ? 'reverse' : 'normal'};
  `}
`;

const RunningText = styled.span`
  ${({ theme: { colors, mq, tp } }) => css`
    display: inline-flex;
    line-height: 1;
    letter-spacing: -0.07em;
    color: #868686;
    font-size: 18.375rem;

    ${tp.useFont(fonts => fonts.Switzer.thin)}

    &::after {
      display: inline-block;
      align-self: center;
      margin: 0 8rem;
      border-radius: 50%;
      background-color: ${colors.primary};
      width: 4rem;
      content: '';
      aspect-ratio: 1 / 1;
    }

    ${mq.md} {
      font-size: 5.625rem;

      &::after {
        margin: 0 2.5rem;
        width: 1.25rem;
      }
    }
  `}
`;

const RunningLine: React.FC<IRunningLine> = ({ texts, reverse }) => {
  return (
    <Container>
      <LineInstance>
        <TextsContainer quickStart reverse={reverse}>
          {texts.map((runningText, index) => (
            <RunningText key={`${runningText}${index}`}>{runningText}</RunningText>
          ))}
        </TextsContainer>
      </LineInstance>
      <LineInstance>
        <TextsContainer reverse={reverse}>
          {texts.map((runningText, index) => (
            <RunningText key={`${runningText}${index}`}>{runningText}</RunningText>
          ))}
        </TextsContainer>
      </LineInstance>
    </Container>
  );
};

export default RunningLine;
