export const BUSINESS_SOLUTIONS_SLICE_TYPE = 'homebusinesssolutions';

export enum BUSINESS_SOLUTIONS_VARIABLES {
  backgroundColor = '--background-color',
  foregroundColor = '--foreground-color',
  primaryTextColor = '--primary-text-color',
  secondaryTextColor = '--secondary-text-color',
  tertiaryTextColor = '--tertiary-text-color',
  mobileSelectColor = '--mobile-select-color',
}
