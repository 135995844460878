import { useMemo } from 'react';
import { RichTextBlock } from 'prismic-reactjs';

import { TCircularExpansionSection } from './CircularExpansionSection';

import {
  ANIMATION_COMPLETE_CLASS,
  useBounceTextEnterAnimation,
} from 'animations/BounceTextEnterAnimation';
import { useMaskCircle } from 'animations/MaskCircle';
import { useRefArray } from 'utils/hooks';
import { richTextWithPlaceholder, splitRichTextByNewLine } from 'utils/prismic';
import { Normalize } from 'utils/types';

const useCircularExpansionAnimation = () => {
  const [leftTextRefs, getLeftTextRef] = useRefArray<HTMLDivElement>();
  const [rightTextRefs, getRightTextRef] = useRefArray<HTMLDivElement>();

  const { containerRef, contentRef } = useMaskCircle([...leftTextRefs, ...rightTextRefs]);

  useBounceTextEnterAnimation(containerRef, leftTextRefs, {
    timelineOptions: {
      onStart: () => {
        contentRef.current?.classList.remove(ANIMATION_COMPLETE_CLASS);
      },
    },
    onStart: () => void 0,
    onComplete: () => void 0,
  });

  useBounceTextEnterAnimation(containerRef, rightTextRefs, {
    enterFromRight: true,
    timelineOptions: {
      delay: 1,
      onStart: () => void 0,
      onComplete: () => {
        contentRef.current?.classList.add(ANIMATION_COMPLETE_CLASS);
      },
    },
    onStart: () => void 0,
    onComplete: () => void 0,
  });

  return {
    containerRef,
    contentRef,
    getLeftTextRef,
    getRightTextRef,
  };
};

export const useCircularExpansionData = ({
  primary,
  items,
}: Normalize<TCircularExpansionSection>) => {
  const {
    home_circular_expansion_background_image: image,
    home_circular_expansion_description: description,
    home_circular_expansion_left_text,
    home_circular_expansion_right_text,
  } = primary;

  const { gatsbyImageData, alt } = image;

  const leftText: RichTextBlock[][] = useMemo(
    () => splitRichTextByNewLine(richTextWithPlaceholder(home_circular_expansion_left_text, '')),
    [home_circular_expansion_left_text]
  );

  const rightText: RichTextBlock[][] = useMemo(
    () => splitRichTextByNewLine(richTextWithPlaceholder(home_circular_expansion_right_text, '')),
    [home_circular_expansion_right_text]
  );

  const runningLineTexts = items.map(item => item.home_circular_expansion_running_text);

  const [primaryLineTexts, secondaryLineTexts] = useMemo(() => {
    const totalRunningTexts = runningLineTexts.length;
    const lineSplitBreakpoint = Math.ceil(totalRunningTexts / 2);

    return [
      runningLineTexts.slice(0, lineSplitBreakpoint),
      runningLineTexts.slice(lineSplitBreakpoint),
    ];
  }, runningLineTexts);

  return {
    backgroundImage: gatsbyImageData ? { image: gatsbyImageData, alt: alt ?? '' } : null,
    description: richTextWithPlaceholder(description, ''),
    leftText,
    rightText,
    primaryLineTexts,
    secondaryLineTexts,
    ...useCircularExpansionAnimation(),
  };
};
