import { TMusicLibrarySection } from './MusicLibrarySection';

import { useNonconcurrentPlay } from 'utils/audioPlayer/useNonconcurrentPlay';
import { richTextWithPlaceholder } from 'utils/prismic';

export const useMusicLibrarySectionData = ({ primary, items }: TMusicLibrarySection) => {
  const { home_music_library_title } = primary;

  const categories = items.reduce(
    (
      acm,
      {
        home_music_library_grid_image: background,
        home_music_library_grid_title: title,
        home_music_library_grid_audio: audio,
      }
    ) => {
      if (!background?.gatsbyImageData || !title?.richText || !audio?.url) return acm;

      return [
        ...acm,
        {
          background: {
            image: background.gatsbyImageData,
            alt: background?.alt ?? '',
          },
          title: richTextWithPlaceholder(title, ''),
          audio: audio.url,
        },
      ];
    },
    []
  );

  return {
    title: richTextWithPlaceholder(home_music_library_title, ''),
    categories,
    ...useNonconcurrentPlay(),
  };
};
