import React, { useLayoutEffect } from 'react';
import styled from '@emotion/styled';
import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { gsap } from 'gsap';
import { CustomEase } from 'gsap/CustomEase';

import { useBusinessSolutionsData } from './hooks';

const buildBackgroundClass = (index: number) => `background-${index + 1}`;

const ANIMATION_DURATION = 3;
const ease = CustomEase.create(
  'custom',
  'M0,0 C0.264,0 0.275,0.057 0.3,0.1 0.344,0.176 0.368,0.29 0.4,0.5 0.434,0.728 0.53,0.811 0.59,0.86 0.636,0.898 0.818,0.986 1,1 '
);

interface ISolutionsBackgrounds {
  solutions: ReturnType<typeof useBusinessSolutionsData>['solutions'];
  activeIndex: null | number;
  isReverse: boolean;
  containerRef: React.RefObject<HTMLDivElement>;
}

const Container = styled.div`
  display: grid;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: transparent;
  width: 100%;
  height: 100%;
`;

const BackgroundImage = styled(GatsbyImage)`
  grid-row: 1 / span 1;
  grid-column: 1 / -1;
  visibility: hidden;
  background-color: transparent;
  width: 100%;
`;

const SolutionsBackgrounds: React.FC<ISolutionsBackgrounds> = ({
  solutions,
  activeIndex,
  isReverse,
  containerRef,
}) => {
  useLayoutEffect(() => {
    const overrideRules = {
      opacity: 1,
      zIndex: 1,
      visibility: 'visible',
    };
    if (activeIndex !== null && containerRef.current) {
      const activeBackgroundElement = containerRef.current?.querySelector(
        `.${buildBackgroundClass(activeIndex)}`
      );

      gsap.fromTo(
        activeBackgroundElement,
        { xPercent: isReverse ? -100 : 100, ...overrideRules },
        {
          xPercent: 0,
          ease,
          duration: ANIMATION_DURATION,
          ...overrideRules,
        }
      );
    }
    return () => {
      if (activeIndex !== null && containerRef.current) {
        const previousBackgroundElement = containerRef.current?.querySelector(
          `.${buildBackgroundClass(activeIndex)}`
        );
        gsap.to(previousBackgroundElement, {
          xPercent: isReverse ? -100 : 100,
          ease,
          duration: ANIMATION_DURATION,
          ...overrideRules,
        });
      }
    };
  }, [activeIndex]);

  return (
    <Container>
      {solutions.map(({ title, background }, index) => (
        <BackgroundImage
          key={`${title}${index}`}
          className={buildBackgroundClass(index)}
          {...background}
          objectFit="contain"
        />
      ))}
    </Container>
  );
};

export const contentBusinessSolutionsSolutionsBackgroundsFragment = graphql`
  fragment HomeContentBusinessSolutionsSolutionsBackgroundsData on PrismicHomeDataContentHomebusinesssolutionsItem {
    home_business_solutions_panel_title
    home_business_solutions_background_image {
      gatsbyImageData
      alt
    }
  }
`;

export default SolutionsBackgrounds;
