import { useHome } from './HomeContext';

import { useLayoutProps, TAlternateLanguages } from 'components/Layout';

export const useHomeData = () => {
  const { lang, alternate_languages, data } = useHome().home;

  return {
    layoutProps: useLayoutProps({
      lang,
      alternate_languages: alternate_languages as TAlternateLanguages,
    }),
    content: data.content,
  };
};
