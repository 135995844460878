import { RefObject } from 'react';
import { gsap } from 'gsap';

import { ANIMATION_COMPLETE_CLASS } from './constants';

import { useAnimationEffect, AnimationEnabledBreakpoints } from 'animations';
import { getDistanceFromPageLeft } from 'animations/helpers';

import TimelineVars = gsap.TimelineVars;

export const useBounceTextEnterAnimation = (
  containerRef: RefObject<HTMLDivElement>,
  textRefs: RefObject<HTMLDivElement>[],
  {
    enterFromRight = false,
    duration = 1.5,
    scrollTrigger = { start: `25% 75%` },
    timelineOptions = {},
    onStart = null,
    onComplete = null,
    enabledBreakpoint = void 0,
  }: {
    enterFromRight?: boolean;
    duration?: number;
    scrollTrigger?: Omit<ScrollTrigger.Vars, 'trigger'>;
    timelineOptions?: Omit<TimelineVars, 'scrollTrigger'>;
    onStart?: null | (() => void);
    onComplete?: null | (() => void);
    enabledBreakpoint?: AnimationEnabledBreakpoints;
  } = {}
) =>
  useAnimationEffect(
    ({ enabled, suspended }) => {
      if (enabled && textRefs.length) {
        const timeline = gsap.timeline({
          scrollTrigger: {
            trigger: containerRef.current,
            ...scrollTrigger,
          },
          ...timelineOptions,
        });

        textRefs.forEach((textRef, index) => {
          if (textRef.current) {
            const text = textRef.current as HTMLDivElement;

            const pageLeft = getDistanceFromPageLeft(text);
            const width = text.offsetWidth;

            const translateX = enterFromRight
              ? window.innerWidth - pageLeft
              : (pageLeft + width) * -1;
            onStart
              ? onStart()
              : textRefs.forEach(({ current }) =>
                  current?.classList.remove(ANIMATION_COMPLETE_CLASS)
                );

            const finalTranslateX = translateX * 1.2;
            timeline.fromTo(
              text,
              {
                translateX: finalTranslateX,
                onStart: () =>
                  onStart
                    ? onStart()
                    : textRefs.forEach(({ current }) =>
                        current?.classList.remove(ANIMATION_COMPLETE_CLASS)
                      ),
              },
              {
                ...(index === textRefs.length - 1
                  ? {
                      onComplete: () =>
                        onComplete
                          ? onComplete()
                          : textRefs.forEach(({ current }) =>
                              current?.classList.add(ANIMATION_COMPLETE_CLASS)
                            ),
                    }
                  : {}),
                translateX: suspended ? finalTranslateX : 0,
                ease: 'bounce',
                duration,
              },
              index ? `-=${duration / 1.5}` : 0
            );
          }
        });
      } else {
        if (timelineOptions.onComplete) {
          timelineOptions.onComplete();
        } else if (onComplete) {
          onComplete();
        } else {
          textRefs.forEach(({ current }) => current?.classList.add(ANIMATION_COMPLETE_CLASS));
        }
      }
    },
    [textRefs],
    enabledBreakpoint
  );
