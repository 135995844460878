import { graphql } from 'gatsby';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';

import Home from './Home';

import { withHead } from 'components/SEO';

export const Head = withHead<Queries.HomeQuery>('home');

export const query = graphql`
  query Home($id: String) {
    home: prismicHome(id: { eq: $id }) {
      ...SEOData
      ...HomeData
      ...HomeLanguageData
      _previewable
    }
  }
`;

export type AllHomesQuery = Queries.AllHomesQuery;

export default withPrismicPreview(Home);
